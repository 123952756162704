@import url('https://fonts.googleapis.com/css2?family=Doto:wght@100..900&family=Oxanium:wght@200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Press+Start+2P&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 1s ease-out;
}

.text-glow {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3),
               0 0 20px rgba(255, 255, 255, 0.3),
               0 0 30px rgba(59, 130, 246, 0.3);
}

/* Lock body scroll */
html, body {
  overflow-x: hidden !important;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background: black; /* Ensures black background even during overflow */
}

#root {
  overflow-x: hidden !important;
  width: 100%;
  position: relative;
  background: black;
}

/* Main scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  html, body {
    overflow-x: hidden;
    position: relative;
  }
  
  #root {
    overflow-x: hidden;
    width: 100%;
    position: relative;
  }
}

/* Prevent horizontal scroll on all devices */
.overflow-x-hidden {
  overflow-x: hidden !important;
}

/* Add this to prevent any element from causing horizontal scroll */
* {
  max-width: 100vw;
  box-sizing: border-box;
}

/* Ensure sections don't cause overflow */
section {
  max-width: 100vw;
  overflow-x: hidden;
}
